<!--
 * @Author: Dyf
 * @Date: 2023-11-07 11:33:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-20 11:32:35
 * @Description: 严选课程详情
-->
<style lang="scss" scoped>
.course-detail {
  @include innerPage($p: 32px 0 0);
  @include pageTitle(34px);

  &-inner {
    width: 100%;
    height: calc(100% - 62px);
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 34px;
    margin-top: 24px;
    overflow: hidden;
    overflow-y: auto;

    h3 {
      line-height: 38px;
      color: #232323;
      text-align: center;
    }

    .other-info {
      height: 30px;
      font-size: 16px;
      color: #696969;
      margin-top: 8px;
      @include flexBox(center);

      span {
        margin: 0 20px;
      }
    }
  }

  &--wrapper {
    width: 100%;
    margin: 40px auto 0;
    @include flexBox;
    align-items: flex-start;

    .label {
      font-size: 16px;
      color: #222222;
    }

    .courseware {
      width: 16.53%;
      min-width: 240px;
      margin: 0 auto;
      @include flexBox;

      .iconfont-color {
        font-size: 44px;
      }

      .el-button {
        margin-left: auto;
      }
    }

    .playback {
      width: 70.13%;
      min-width: 500px;
      margin: 0 auto;
      @include flexBox;
      align-items: flex-start;

      .label {
        width: 106px;
        line-height: 34px;
      }

      .medium {
        width: calc(100% - 106px);
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 30px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .course-detail {
    @include innerPage($p: 18px 0 0);
  }
  .course-detail-inner .other-info,.course-detail--wrapper .label {
    font-size: 14px;
  }
}
</style>

<template>
  <section class="course-detail">
    <div class="page-title">
      <h3>课程详情</h3>
      <el-button type="custom_primary" size="small" @click="$router.go(-1)">
        返回
      </el-button>
    </div>
    <div class="course-detail-inner">
      <h3 class="bold">{{ detailData.plstr_cur_title || "-" }}</h3>
      <div class="other-info pf_medium">
        <span>章节：{{ detailData.plstr_cur_lable }}</span>
        <span>科目：{{ detailData.sysub_name }}</span>
        <span>授课老师：{{ detailData.teuse_name }}</span>
      </div>
      <div class="course-detail--wrapper">
        <div class="playback" v-if="detailData.video_url">
          <p class="label">视频回放：</p>
          <div class="medium">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
            />
          </div>
        </div>
        <div class="courseware" v-else>
          <p class="label">课件：</p>
          <i class="iconfont-color" v-html="fileType(detailData.file_type)"></i>
          <el-button
            type="custom_primary"
            size="medium"
            @click="downloadFile"
            :disabled="btnLoad"
            v-loading="btnLoad"
          >
            下载
          </el-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { plastrictcurriculumInfo, add_down } from "@api/home";
import { $downloadFile } from "@api/common";
import { formatFile, formatFileType } from "@utils";
export default {
  computed: {
    fileType() {
      return function (val) {
        return formatFileType(val);
      };
    },
  },
  data() {
    return {
      detailData: {},
      btnLoad: false,
      /* 视频播放器配置 */
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 视频封面
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controls: true,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    /** 获取课程详情 */
    async getDetail() {
      const videoType = [
        "mp4",
        "avi",
        "wmv",
        "rmvb",
        "rm",
        "flash",
        "mid",
        "3gp",
        "mpeg",
      ]; // 视频文件类型
      let { data } = await plastrictcurriculumInfo({
        plstr_cur_id: this.$route.params.id,
      });
      let file_type = data.plstr_cur_url.substring(
        data.plstr_cur_url.lastIndexOf(".") + 1
      );
      data.file_type = file_type;
      if (videoType.indexOf(file_type) != -1) {
        data.video_url = data.plstr_cur_url;
      }
      this.detailData = { ...data };
      this.playerOptions = {
        ...this.playerOptions,
        sources: [
          {
            type: "video/mp4",
            src: formatFile(data.video_url),
          },
        ],
      };
      this.$forceUpdate();
    },
    /** 文件下载 */
    async downloadFile() {
      this.btnLoad = true;
      await add_down({ plstr_cur_id: this.detailData.plstr_cur_id });
      let { data } = await $downloadFile(this.detailData.plstr_cur_url);
      this.btnLoad = false;
      if (data.url) {
        this.$fileDownload(data.url);
      }
    },
  },
};
</script>